import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { setFilter1, setFilter2 } from "../state/actions"
import { SwitchTransition, CSSTransition } from "react-transition-group"
import { Link } from "gatsby"

import ArticlesGrid from "components/articlesGrid"
import ArticleSelect from "components/articleSelect"
import styles from "styles/components/filtered-articles-grid.module.scss"
import PaddedContent from "components/paddedContent"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const FilteredArticlesGrid = ({
  articles,
  tagsList,
  categoryLabels,
  categories,
  filter,
  filter1,
  setFilter1,
  filter2,
  setFilter2,
  blogSulg
}) => {
  if (!blogSulg) blogSulg = "blog";
  if (tagsList === null) tagsList = []
  const [tags, setTags] = useState(
    tagsList.map((tag) => ({ value: tag.text, label: tag.text }))
  )
  let categoryOptions = [{ label: "All", value: null, link: `/${blogSulg}` }]
  let labels = []
  for (let i = 0; i < (categories || []).length; i++) {
    const label = categoryLabels[i] ? categoryLabels[i] : categories[i].text;
    const link = "/" + blogSulg + "/" + label.toLowerCase().replace(" ", "-")
    categoryOptions.push({
      label,
      value: categories[i].text?.trim(),
      link: link
    })
    labels.push(categories[i].text)
  }

  // add or remove "View All" option from tags array
  useEffect(() => {
    if (tags.length > 0) {
      if (filter2.value !== null && tags[0].value !== null) {
        // we need to add to the front of the tags array
        let updatedTags = tags.slice(0)
        updatedTags.unshift({ value: null, label: "View All" })
        setTags(updatedTags)
      }
      if (filter2.value === null && tags[0].value === null) {
        setTags(tags.slice(1))
      }
    }
  }, [filter2, tags, setTags])

  // filter articles
  if (filter) {
    filter1 = filter;
  }
  let filteredArticles = articles
    .filter((article) => {
      if (filter1 === null) {
        // EC-16664: Marketing site deployment failed
        return labels.includes(article.category?.text);
      }
      return article?.category?.text.trim() === filter1;
    })
    .filter((article) => {
      if (filter2.value === null) return true
      if (!article.tags) return false
      for (let i = 0; i < article.tags.length; i++) {
        let tag = article.tags[i]
        if (filter2.value === tag.text) return true
      }
      return false
    })
    // sort by date
    .sort((a, b) => {
      let partsA = (a.date || '').split("-")
      let partsB = (b.date || '').split("-")
      return (
        new Date(partsB[0], partsB[1] - 1, partsB[2]) -
        new Date(partsA[0], partsA[1] - 1, partsA[2])
      )
    })
  return (
    <div className={styles.container}>
      <PaddedContent>
        <div className={styles.filters}>
          <div className={styles.typeFilters}>
            {categoryOptions.map((option, i) => (
              //   <button
              //   key={option.label}
              //   className={cx("typeFilter", {
              //     active: filter1 === option.value,
              //   })}
              //   onClick={() => {
              //     setFilter1(option.value)
              //   }}
              // >
              //   {option.label}
              // </button>
              <Link
                key={option.label}
                className={cx("typeFilter", {
                  active: filter1 === option.value,
                })}
                to={option.link}
              >
                {option.label}
              </Link>
            ))}
          </div>
          {tags.length > 0 && (
            <div className={styles.selectWrapper}>
              <ArticleSelect
                options={tags}
                onChange={setFilter2}
                value={
                  filter2 ? (filter2.value === null ? null : filter2) : null
                }
              />
            </div>
          )}
        </div>
      </PaddedContent>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${filter1}${filter2.value}`}
          classNames="article-fade"
          timeout={200}
        >
          <ArticlesGrid articles={filteredArticles} />
        </CSSTransition>
      </SwitchTransition>
    </div>
  )
}

const mapStateToProps = ({ filter1, filter2 }) => ({
  filter1,
  filter2,
})

const mapDispatchToProps = (dispatch) => ({
  setFilter1: (value) => dispatch(setFilter1(value)),
  setFilter2: (value) => dispatch(setFilter2(value)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilteredArticlesGrid)
